
import NuxtSSRScreenSize from 'nuxt-ssr-screen-size'
export default {
	data() {
		return {
			items: [],
		}
	},
	mixins: [NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],
	async fetch() {
    this.items = await this.$axios.$get('https://elektrikerpris.dk/files/json/reviews.json')
  },
	methods: {
	},
	mounted() {
		console.log(this.items)
		if (!process.server) {
			console.log(this.$vssWidth)
		}
	},
}
